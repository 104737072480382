import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Prof from "../components/Prof";
import "../../css/Elenco.css";
import Swal from "sweetalert2";
import packageInfo from "../../config.json";
import BackToTopButton from "../components/BackToTopButton";
import useCommon from "../useCommon";
import Aiuto from "../components/Aiuto";

const Elenco = () => {
	// importo massimo e minimo voti
	const MIN = packageInfo.MIN;
	const MAX = packageInfo.MAX;
	// Dichiarazione costanti dal json
	const SERVER = packageInfo.SERVER;
	const { notLogged } = useCommon();

	const [PROFF, setPROFF] = useState([]); // array globale con i prof ricavati dal JSON
	const navigate = useNavigate();

	useEffect(() => {
		let infos = localStorage.getItem("vd-info");
		// se non ci sono le infomazioni necessarie faccio rifare il login
		if (localStorage.getItem("vd-info") === null) {
			notLogged();
		} else if (Object.keys(JSON.parse(localStorage.getItem("vd-info"))).length === 0) {
			notLogged();
		} else {
			let endpoint = JSON.parse(infos).urlEndpoint;
			fetch(SERVER + "/" + endpoint, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: JSON.parse(infos).token,
				}),
			})
				.then((testo) => testo.json())
				.then((data) => {
					if (data.docenti !== undefined) {
						setPROFF(data.docenti);
					} else {
						Swal.fire({
							icon: "warning",
							title: "Impossibile caricare i docenti",
							text: data.messaggio,
							confirmButtonText: "Torna alla home",
							confirmButtonColor: "#008ed4"
						}).then((result) => {
							if (result.isConfirmed) {
								navigate("/Home");
							}
						});
					}
				});
		}
	}, []);

	// creo i prof
	const creaProf = () => {
		if (localStorage.getItem("vd-info") !== null) {
			return PROFF.map((e, i) => {
				// per ogni object professore ricavo le materie
				let materie = e.materie;
				// creo il prof con le materie che ha
				return (
					<Prof
						nome={e.nome}
						cognome={e.cognome}
						mail={e.mailDocente}
						materie={materie}
						key={i}
						indice={i}
					/>
				);
			});
		}
	};

	document.title = "Elenco dei professori - Valutazione Docenti";

	return (
		<div className="container-fluid">
			<Header />
			<div className="row justify-content-center align-items-center mt-5 w-75 m-auto">
				<h2 style={{"color": "black", "fontSize": "40px"}} className="text-center mb-4">
					Elenco dei professori da valutare
				</h2>
				<i className="text-center mb-2" style={{"color": "black", "fontSize": "20px"}}>
					È possibile valutare i propri professori in un range numerico che va da {MIN} a {MAX}.
					<br/>
					Una volta concluso il voto di un professore, questo scomparirà dall'elenco sottostante.
				</i>
				{creaProf()}
			</div>
			<Footer />
			<Aiuto pagina={"Elenco"}/>
			<BackToTopButton />
		</div>
	);
};

export default Elenco;
