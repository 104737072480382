import {React,useRef,useEffect,useState} from "react";
import { Bar } from "react-chartjs-2";
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend,} from 'chart.js';
import packageInfo from "../../config.json";
import { useNavigate } from "react-router-dom";
import "../../css/Docente.css"
import useCommon from "../useCommon";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const Grafico = () => {
    const SERVER=packageInfo.SERVER

    let nome, cognome, token;
    const [nomeCognome, setNomeCognome] = useState();
	const [medie, setMedie] = useState()
	const [domand,setDomand] = useState()
	const [datiProf, setDatiProf] = useState()

    const navigate=useNavigate()
	const { notLogged } = useCommon();

    const grafo = useRef()
	const domande = useRef()
	const graph = useRef()
	const selMat = useRef()

    // ricavo funzioni da file
    let labels=[]
	if(domand!==undefined){
		for (let k=0;k<domand.length;k++)
			labels.push("Domanda " + parseInt(k+1))
	}
	const data={
		labels: labels,
		datasets:[{
			backgroundColor: "rgba(0,142,212,1)",
			hoverBackgroundColor: "rgba(0,182,252,1)",
			label: 'Media totale per domanda',
			data: medie,
			skipNull: true
		}]
	}

	const options={
		responsive: true,
		scales: {
			y:{
				// max: 10,
				stepSize:1,
			  },
			x:{},
		  },
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				text: 'Diagramma delle valutazioni ricevute da ' + nomeCognome
			},
		}
	}

	useEffect(()=>{
		if (localStorage.getItem("vd-info") === null) {
			notLogged();
		} else if (Object.keys(JSON.parse(localStorage.getItem("vd-info"))).length === 0) {
			notLogged();
		} else {
			let view_docente=JSON.parse(localStorage.getItem("vd-info")).urlEndpoint
			let mail=JSON.parse(localStorage.getItem("vd-info")).email
			token = JSON.parse(localStorage.getItem("vd-info")).token;
			
			fetch(SERVER + "/" + view_docente, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: token,
					email: mail,
				}),
			})
			.then(datiProf=>datiProf.json())
			.then(profDati=>{			
				if (profDati.materie!=null){
					setDatiProf(profDati)
					nome = profDati.nome
					cognome = profDati.cognome
					setNomeCognome(nome + " " + cognome)
					let med=[]
					let doman=[]
					for (let i=0;i<profDati.materie.length;i++){
						for(let j=0;j<profDati.materie[i].valutazioni.length;j++){
							med.push(profDati.materie[i].valutazioni[j].voto)	
						}
					}
					for(let l=0;l<profDati.materie[0].valutazioni.length;l++){
						doman.push(profDati.materie[0].valutazioni[l].domanda)
					}
					setMedie(med)
					setDomand(doman)
					stampaDomande(doman)
					creaGrafico()
				}
			})
		}
	},[nome, cognome])

	const creaGrafico=()=>{
		if (datiProf!=null){
			let med=[]
			for (let i=0;i<datiProf.materie.length;i++){
				if (datiProf.materie[i].nome==selMat.current.value){
					for(let j=0;j<datiProf.materie[i].valutazioni.length;j++){
						med.push(datiProf.materie[i].valutazioni[j].voto)	
					}
				}
			}
			setMedie(med)
		}
	}

	const selectMateria=()=>{

		if(datiProf!=null){
			let materie=[]
			for (let i=0;i<datiProf.materie.length;i++){
				materie.push(datiProf.materie[i].nome)
			}
			return materie.map((e,i)=>{
				return(
					<option key={i} value={e}>
						{e}
					</option>
				)
			})
			
		}
	}

    const stampaDomande=(questions)=>{

		for (let j=0;j<questions.length;j++){
			questions[j] = "Domanda " + parseInt(j+1) + ". " + questions[j] + "<br/><br/>"
			domande.current.innerHTML+=questions[j]
		}		
	}


  return (
    <div id="graf">
		<select className="adminButton adminSelect m-2" onChange={creaGrafico} ref={selMat}>{selectMateria()}</select>
		<br />
		<span className="infoMaterie">
			Nel menu a tendina precedente è possibile selezionare la
			materia della quale visualizzare le medie nel caso se ne insegnino molteplici.
		</span>
			<div className="d-none" id="contenitore_grafico" ref={graph}></div>
		<Bar id="grafico" className="d" options={options} data={data}></Bar>
		{/* <div className="contBottPdf"><input type="button" value="Scarica il PDF contenente le valutazioni" id="pdf" onClick={scaricaPDF}/></div> */}
		<div className="d-flex align-items-center justify-content-center">
		<div className="col-12 doman" ref={domande}></div>	
		</div>
	</div>
  )
}

export default Grafico