import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Login.css";
import Header from "../components/Header-login";
import Footer from "../components/Footer-login";
import packageInfo from "../../config.json";
import Aiuto from "../components/Aiuto";
import logoGoogle from "../../images/google.png";
import {
	GoogleLogin,
	useGoogleLogin,
	GoogleOAuthProvider,
  } from "@react-oauth/google";

const Login = () => {
	// Dichiarazione costanti dal json
	const SERVER = packageInfo.SERVER;
	const LOGIN = packageInfo.LOGIN;
	const caricamento = useRef()
	const barra = useRef()
	let aggiorna
	const [msgErrore, setmsgErrore] = useState(""); // stato in cui salvo il messaggio di errore
	const errorMessage = useRef(); // ref dell'elemento in cui mostro il messaggio di errore

	useEffect(() => {
		// controllo se loggato
		if (localStorage.getItem("vd-info") !== null) {
			if (Object.keys(JSON.parse(localStorage.getItem("vd-info"))).length !== 0){
				navigate("/Home", { replace: true });
			}
		}
		// per evitare che quando utente log out, rimanga nome in title
		document.title = "Login - Valutazione Docenti";
	}, []);

	// salvo valori
	const username = useRef();
	const password = useRef();
	const eye = useRef();

	let navigate = useNavigate();

	// invio dati form se tasto invio premuto
	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			checkLogin();
		}
	};

	// mostra password
	const showPsw = () => {
		if (password.current.type === "password") {
			password.current.type = "text";
			eye.current.className = "bi bi-eye-slash";
		} else if (password.current.type === "text") {
			password.current.type = "password";
			eye.current.className = "bi bi-eye";
		}
	};

	// controllo credenziali
	const checkLogin = (credentialResponse) => {
		// let valUser = username.current.value.trim(),
		// 	valPassword = password.current.value.trim();
		console.log(credentialResponse.credential);
		fetch(SERVER + LOGIN, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ token: credentialResponse.credential }),
		})
			.then((dati) => dati.json())
			.then((info) => {	
				if (typeof info.token !== 'undefined') {
					clearInterval(aggiorna);
					caricamento.current.className = "progress mt-5 d-none"
					localStorage.setItem("vd-info", JSON.stringify(info));
					navigate("/Home", { replace: true });
				} else {
					errore("Nome utente o password errati");
				}
			});
	};

		// controllo credenziali
		const checkError = () => {
			errore("Nome utente o password errati");
		};
	const errore = (msg) => {
		setmsgErrore(msg);
		errorMessage.current.className = "d-flex error w-100";
	};
let percentuale=0
const avviaBarra=()=>{
	caricamento.current.className = "progress mt-5"
	
	aggiorna= setInterval(() => {
		if(percentuale<90){
			percentuale+=10
			barra.current.style="width:" + percentuale + "%"
		}

	}, 1000);
}
	return (
		<div className="container-fluid">
			<Header />
			{/* Error card */}
			<div className="row justify-content-center align-items-center mt-5">
				<div className="col-10 col-md-6 d-flex align-items-center">
					<div className="d-none error w-100" ref={errorMessage}>
						<div className="error__icon me-3">
							<svg
								fill="none"
								height="24"
								viewBox="0 0 24 24"
								width="24"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="m13 13h-2v-6h2zm0 4h-2v-2h2zm-1-15c-1.3132 0-2.61358.25866-3.82683.7612-1.21326.50255-2.31565 1.23915-3.24424 2.16773-1.87536 1.87537-2.92893 4.41891-2.92893 7.07107 0 2.6522 1.05357 5.1957 2.92893 7.0711.92859.9286 2.03098 1.6651 3.24424 2.1677 1.21325.5025 2.51363.7612 3.82683.7612 2.6522 0 5.1957-1.0536 7.0711-2.9289 1.8753-1.8754 2.9289-4.4189 2.9289-7.0711 0-1.3132-.2587-2.61358-.7612-3.82683-.5026-1.21326-1.2391-2.31565-2.1677-3.24424-.9286-.92858-2.031-1.66518-3.2443-2.16773-1.2132-.50254-2.5136-.7612-3.8268-.7612z"
									fill="#393a37"
								></path>
							</svg>
						</div>
						<div className="error__title">{msgErrore}</div>
					</div>
				</div>
			</div>
			{/* User input */}
			<div className="accountGoogle">
				<img src={logoGoogle} className="pngGoogle" alt="" srcset="" />
				<div className="loginGoogle d-flex justify-content-center" onClick={avviaBarra}>
					<GoogleOAuthProvider clientId="686508933028-3p8ja659ohikbml6g5a250o4etf95i43.apps.googleusercontent.com">
						<GoogleLogin
								onSuccess={checkLogin}
								onFailure={checkError}
								onClick={avviaBarra}
							/>
					</GoogleOAuthProvider>

				</div>
			</div>
			<div className="progress mt-1 d-none" ref={caricamento}>
  						<div ref={barra} className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width:"0%"}}></div>
			</div>
			<Footer />
			<Aiuto pagina={"Login"}/>
		</div>
	);
};

export default Login;
