import {React,useRef,useEffect,useState} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Aiuto from "../components/Aiuto";
import { Bar } from "react-chartjs-2";
import {Chart as ChartJS,CategoryScale,LinearScale,BarElement,Title,Tooltip,Legend,} from 'chart.js';
import "../../css/Docente.css"
import packageInfo from "../../config.json";
import Grafico from "../components/Grafico"
import useCommon from "../useCommon";
import BackToTopButton from "../components/BackToTopButton";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);



const Docente = () => {
	const SERVER=packageInfo.SERVER
	//const SCARICA_PDF=packageInfo.SCARICA_PDF

	let nome, cognome, token;
    const [nomeCognome, setNomeCognome] = useState();
	const [medie, setMedie] = useState()
	const [domand,setDomand] = useState([])
	const [datiProf, setDatiProf] = useState()

	const { notLogged } = useCommon();

	const grafo = useRef()
	const niente = useRef()
	const iniziate = useRef()
	const tastoAiuto = useRef()

	let labels=[]
	if(domand!==undefined){
		for (let k=0;k<domand.length;k++)
			labels.push("Domanda " + parseInt(k+1))
	}
	const data={
		labels: labels,
		datasets:[{
			backgroundColor: "rgba(0,142,212,1)",
			hoverBackgroundColor: "rgba(0,182,252,1)",
			label: 'Media totale per domanda',
			data: medie,
			skipNull: true
		}]
	}

	const options={
		responsive: true,
		scales: {
			y:{
				max: 10,
				stepSize:1,
			  },
			x:{},
		  },
		plugins: {
			legend: {
				position: 'top',
			},
			title: {
				display: true,
				text: 'Diagramma delle valutazioni ricevute da ' + nomeCognome
			},
		}
	}

	useEffect(()=>{
		if (localStorage.getItem("vd-info") === null) {
			notLogged();
		} else if (Object.keys(JSON.parse(localStorage.getItem("vd-info"))).length === 0) {
			notLogged();
		} else {
			token = JSON.parse(localStorage.getItem("vd-info")).token;
			let view_docente=JSON.parse(localStorage.getItem("vd-info")).urlEndpoint
			let mail=JSON.parse(localStorage.getItem("vd-info")).email
			
			// fetch(SERVER + "/" + view_docente,{
			// 	method: "POST",
			// 	headers: { "Content-Type": "application/json" },
			// 	body: JSON.stringify({token:token,email:mail}),
			// })
			fetch(SERVER + "/" + view_docente, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: token,
					email: mail,
				}),
			})
			.then(datiProf=>datiProf.json())
			.then(profDati=>{			
				if(profDati.materie==null){
					grafo.current.className="d-none"
					iniziate.current.className="d-none"
					niente.current.className="row nonVal"
					tastoAiuto.current.className="d-none"
					
					// Non ti dice se le valutazioni sono iniziate o no
					// if (profDati.messaggio[11]==='d'){
					// 	grafo.current.className="d-none"
					// 	niente.current.className="d-none"
					// 	iniziate.current.className="row nonVal"
					// }
				}
				else{
					setDatiProf(profDati)
					nome = profDati.nome
					cognome = profDati.cognome
					let med=[]
					let doman=[]
					for (let i=0;i<profDati.materie.length;i++){
						for(let j=0;j<profDati.materie[i].valutazioni.length;j++){
							med.push(profDati.materie[i].valutazioni[j].voto)	
						}
					}
					for(let l=0;l<profDati.materie[0].valutazioni.length;l++){
						doman.push(profDati.materie[0].valutazioni[l].domanda)
					}
					setMedie(med)
					setDomand(doman)
					iniziate.current.className="d-none"
					tastoAiuto.current.className="d"
					grafo.current.className="row"
					niente.current.className="d-none"
				}
			})
		}
	},[nome,cognome])
	

	// const scaricaPDF=()=>{
	// 	CheckToken()
	// 	.then(mess=>{
	// 		if(mess.valido){
	// 			fetch(SERVER + SCARICA_PDF,{
	// 				method:"POST",
	// 				headers:{"Content-Type":"application/json"},
	// 				body: JSON.stringify({token:token,nome_docente:nome,cognome_docente:cognome,valutazioni:medie})
	// 			})
	// 			.then(scarica=>scarica.blob())
	// 			.then(scarica=>{
	// 				let url=document.createElement("a")
	// 				url.target="new"
	// 				url.download=`medie_${nome}_${cognome}.pdf`
	// 				url.href=window.URL.createObjectURL(scarica)
	// 				url.click()
	// 			})
	// 		}
	// 	})
	// } NON FUNZIONANTE/DA IMPLEMENTARE
	


	document.title = "Visualizzazione media valutazioni - " + nome + " " + cognome;

	return( 
		<div className="container-fluid">
			<Header/>
			<div className="d-none" id="contenitore_grafico" ref={grafo}>
				<Grafico/>	
				{/* <select className="adminButton adminSelect m-2" onChange={creaGrafico} ref={selMat}>{selectMateria()}</select>
				<div className="d-none" id="contenitore_grafico" ref={graph}></div>
					<Bar id="grafico" className="d" options={options} data={data}></Bar>
					{/* <div className="contBottPdf"><input type="button" value="Scarica il PDF contenente le valutazioni" id="pdf" onClick={scaricaPDF}/></div> 
					<div className="d-flex align-items-center justify-content-center">
					<div className="col-12 doman" ref={domande}></div>	
				</div> */}
            
			</div>
			<div className="row nonVal" ref={niente}>
				Il docente non è ancora stato valutato
			</div>
			<div className="row nonVal" ref={iniziate}>
				Durante il periodo di valutazione non è possibile visualizzare le medie
			</div>
			<div className="d-none" ref={tastoAiuto}>
				<Aiuto pagina={"Docente"}/>
			</div>
			<BackToTopButton />
			<Footer/>
		</div>
	)
};

export default Docente;
