import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Login.css";
import "../../css/Home.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import useCommon from "../useCommon";

const Home = () => {
	const infos = JSON.parse(localStorage.getItem("vd-info"));
	const submitBtn = useRef();
	const navigate = useNavigate();
	const { notLogged } = useCommon();
	useEffect(() => {
		// se non ci sono le infomazioni necessarie faccio rifare il login
		if (localStorage.getItem("vd-info") === null) {
			notLogged();
		} else if (Object.keys(JSON.parse(localStorage.getItem("vd-info"))).length === 0) {
			notLogged();
		}
		document.title = "Home - " + handleNameShown();
	}, []);

	const handleNameShown = () => {
		// per togliere la parte dopo la @
		// uso 'if' dato che se non ci fosse darebbe errore a fare la split di undefined all'inizio
		if (infos !== null) {
			let ins = infos.email;
			let user;
			try {
				user = ins.split("@")[0];
			} catch {
				user = ins;
			}

			try {
				return user.split(".").map((e)=> {
					return firstLetterUpper(e);
				}).join(" ");
			} catch {
				return "";
			}
		}
	};

	const showText = () => {
		if (infos !== null) {
			// ottengo l'endpoint ricevuto dal server
			let endpoint = infos.urlEndpoint;

			// controllo
			if (endpoint === "get_docenti_classe") { // studente
				return "Valuta";
			} else if (endpoint === "view_docente") { // docente
				return "Visualizza medie delle valutazioni";
			} else if (endpoint === "admin_console") { // admin
				return "Gestione delle valutazioni";
			}
		}
	}

	const redirectToPage = () => {
		if (infos !== null) {
			// ottengo l'endpoint ricevuto dal server
			let endpoint = infos.urlEndpoint;

			// controllo
			if (endpoint === "get_docenti_classe") { // studente
				navigate("/Elenco");
			} else if (endpoint === "view_docente") { // docente
				navigate("/Docente");
			} else if (endpoint === "admin_console") { // admin
				navigate("/Admin");
			}
		}
	}

	const firstLetterUpper = (e) => {
		return e.charAt(0).toUpperCase() + e.slice(1);
	};


	return (
		<div className="container-fluid">
			<Header />
			<div className="row justify-content-center align-items-center mt-3">
				<div className="col-12 d-flex justify-content-center align-items-center mt-5 mb-4">
					{/* messaggio di benvenuto */}
					<p className="text-infos text-center">
						Benvenuto/a,
						<br />
						{handleNameShown()}
					</p>
				</div>
				<div className="col-12 d-flex justify-content-center align-items-center mt-4">
					{/* bottone invio */}
					<div className="w-50 text-center">
						<button className="btn-login text-center" onClick={redirectToPage} ref={submitBtn}>
							{showText()}
						</button>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Home;
