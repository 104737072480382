import React, {useRef} from "react";
import Swal from "sweetalert2";
import "../../css/PulsanteAiuto.css"

const Aiuto = (props) => {

    const nascondi = useRef()

	const aiuto=()=>{
        let pagina=props.pagina
        switch (pagina){
            case "Login":
                Swal.fire({
                    icon: "info",
                    title: "Browser",
                    html: "<i><b>Per accedere è necessario utilizzare l'account scolastico che termina con @denina.it</b></i>.<br/>Se si sta usando Safari come browser, il login potrebbe non funzionare, in caso succedesse cambiare browser.",
                    confirmButtonText: "Va bene",
                    confirmButtonColor: "#008ed4"
                })
                break
            case "Elenco":
                Swal.fire({
                    icon: "info",
                    title: "Valutare",
                    text: "Per valutare il docente, cliccare sulla casella contenente il docente che si desidera valutare.",
                    confirmButtonText: "Va bene",
                    confirmButtonColor: "#008ed4"
                })
                break
            case "Valutazione":
                Swal.fire({
                    icon: "info",
                    title: "Valutazione",
                    text: "Se si desidera valutare una sola materia, utilizzare il menu a tendina apposito, che normalmente è impostato per valutare entrambe le materie.",
                    confirmButtonText: "Va bene",
                    confirmButtonColor: "#008ed4"
                })
                break
            case "Docente":
                Swal.fire({
                    icon: "info",
                    title: "Medie",
                    text: "Per vedere la media precisa, cliccare la barra corrispondente nel grafico. È possibile scegliere di quale delle materie insegnate visualizzare i voti semplicemente scegliendo la materia dal menu a tendina nella parte alta della pagina.",
                    confirmButtonText: "Capito",
                    confirmButtonColor: "#008ed4"
                })
                break
            case "Admin":
                Swal.fire({
                    icon: "info",
                    title: "Pannello di controllo",
                    html: "<h2 style='color: grey; margin-top: 10px'>Visualizzazione medie docenti</h2>È possibile visualizzare la media delle valutazioni ottenute da un docente semplicemente selezionandolo dal menu a tendina nella parte superiore della pagina e cliccando il tasto \"Visualizza docente\".<br/>" + 
                    "<h2 style='color: grey; margin-top: 10px'>Caricamento dei dati</h2>Tramite il pulsante \"Carica dati\", tutte le informazioni riguardanti i docenti e gli studenti verranno reinserite all'interno del database. <b>Ciò comporta la possibilità degli studenti di votare nuovamente tutti i docenti</b>.<br/>" +
                    "<h2 style='color: grey; margin-top: 10px'>Gestione delle valutazioni</h2>È possibile, in qualsiasi momento, avviare o fermare le valutazioni premendo il rispettivo tasto situato al fondo della pagina.",
                    confirmButtonText: "Capito",
                    confirmButtonColor: "#008ed4"
                })
                break
            default:
                Swal.fire({
                    icon: "error",
                    title: "Errore",
                    text: "Non ho un aiuto da offrire per questa pagina",
                    confirmButtonColor: "#008ed4"
                })
        }
		
	}

    return (
        <button className="d" id="pulsanteAiuto" title="Aiuto" onClick={aiuto} ref={nascondi}>
            ?
        </button>
    )
}

export default Aiuto