import React from "react";
import { useNavigate } from "react-router-dom";

const Prof = (props) => {
    const navigate = useNavigate();
    return (
        <div className="col-12 col-lg-3 person-container" key={props.indice} onClick={()=> {navigate("/Valutazione", {state: {
            nome: props.nome,
            cognome: props.cognome,
            mail: props.mail,
            materia: props.materie
        }})}}>
            <h4>{props.nome + " " + props.cognome}</h4>
            <hr />
            {props.materie.map((materia)=> {
                return <>
                    <div className="mt-2">{materia}</div>
                    <br/>
                </>
            })}
        </div>
    )
}

export default Prof