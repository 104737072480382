// importo componenti di default
import React from "react";
import { Route, Routes } from "react-router-dom"; // ! da importare oltre che node modules
// importo fogli di stile
import "./css/App.css";
// importo i miei componenti
import Login from "./js/pages/Login";
import Home from "./js/pages/Home";
import Elenco from "./js/pages/Elenco";
import Valutazione from "./js/pages/Valutazione";
import Admin from "./js/pages/Admin";
import Docente from "./js/pages/Docente";

const App = () => {
	return (
		// Cambio solo quello che c'è nel TAG Routes
		<Routes>
			{/* pagina di default (login) */}
			<Route path="/" element={<Login />} />
			{/* mostro info utente loggato */}
			<Route path="/Home" element={<Home />} />
			{/* elenco professori */}
			<Route path="/Elenco" element={<Elenco />} />
			{/* valutazione professore per materia */}
			<Route path="/Valutazione" element={<Valutazione />} />
			{/* pagina dell'admin */}
			<Route path="/Admin" element={<Admin />} />
			{/* pagina del docente */}
			<Route path="/Docente" element={<Docente />} />
			{/* pagina 404 (login) */}
			<Route path="*" element={<Login />} />
		</Routes>
	);
};

export default App;
