import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const useCommon = () => {
	const navigate = useNavigate();

	const Logout = () => {		
		// pulisco i dati dalla local storage
		localStorage.setItem("vd-info", "{}");
		// redirect verso la pagina di login
		navigate("/");
	}

	const notLogged = () => {
		Swal.fire({
			icon: "warning",
			title: "Sessione scaduta",
			text: "Non vi sono informazioni riguardanti la sessione autenticata precedente, pertanto è necessario effettuare nuovamente il login per poter utilizzare la web app.",
			confirmButtonText: "Torna alla pagina di login",
			confirmButtonColor: "#008ed4"
		}).then((result) => {
			if (result.isConfirmed) {
				navigate("/");
			}
		});
	}

	return { Logout, notLogged }
};

export default useCommon
