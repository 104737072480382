import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Bar } from "react-chartjs-2";
import BackToTopButton from "../components/BackToTopButton";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	plugins,
} from "chart.js";
import Swal from "sweetalert2";
import packageInfo from "../../config.json";
import "../../css/Admin.css";
import useCommon from "../useCommon";
import Aiuto from "../components/Aiuto";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const Admin = () => {
	// Dichiarazione costanti dal json
	const SERVER = packageInfo.SERVER;
	const ADMIN_CONSOLE = packageInfo.ADMIN_CONSOLE;
	const GET_DOCENTI = packageInfo.GET_DOCENTI;
	const VIEW_DOCENTE = packageInfo.VIEW_DOCENTE;

	const { notLogged } = useCommon();
	const selectDocente = useRef();
	const divPulsante = useRef();

	const navigate = useNavigate();
	const [token, setToken] = useState(
		JSON.parse(localStorage.getItem("vd-info"))
	);

	const [dataDocenti, setDataDocenti] = useState({});
	const [votiDocenti, setVotiDocenti] = useState({});

	useEffect(() => {
		// rimuovo eventuali pulsanti precedenti
		divPulsante.current.innerHTML = "";
		async function renderAdminConsole() {
			fetch(SERVER + ADMIN_CONSOLE, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify({ token: token.token }),
			})
				.then((dati) => dati.json())
				.then((data) => {
					if (data.errore) notLogged();
					else {
						data.map((e, i) => {
							if (e.button !== undefined) {
								let btn = document.createElement("input");
								btn.setAttribute("type", "button");
								btn.classList.add("adminButton", "m-2");
								btn.setAttribute("value", e.button);
								btn.addEventListener("click", () =>
									handle_admin_function(e.urlEndpoint)
								);
								divPulsante.current.appendChild(btn);
							}
						});
					}
				});
		}
		if (token === null || Object.keys(token).length === 0) notLogged();
		else renderAdminConsole();
	}, []);

	useEffect(() => {
		async function fetchGetDocenti() {
			fetch(SERVER + GET_DOCENTI, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ token: token.token }),
			})
				.then((_data) => _data.json())
				.then((data) => {
					setDataDocenti(data);
				});
		}
		if (token === null || Object.keys(token).length === 0) notLogged();
		else fetchGetDocenti();
	}, []);

	const handle_admin_function = async (endpoint) => {
		if (endpoint === "carica_dati") {
			fetch(SERVER + "/" + endpoint)
				.then((dati) => dati.json())
				.then((data) => {
					Swal.fire({
						icon: "info",
						title: data.messaggio,
						timer: 2000,
					});
				});
		} else {
			fetch(SERVER + "/" + endpoint, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ token: token.token }),
			})
				.then((dati) => dati.json())
				.then((data) => {
					if (data.messaggio !== undefined)
						Swal.fire({
							icon: "info",
							title: data.messaggio,
							timer: 2000,
							showConfirmButton: false,
							allowOutsideClick: false,
						}).then(() => {
							window.location.reload();
						});
				});
		}
	};

	const view_docente_function = async () => {
		const selectedOption = selectDocente.current.value;
		if (selectedOption !== "") {
			if (dataDocenti) {
				fetch(SERVER + VIEW_DOCENTE, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						token: token.token,
						email: selectedOption,
					}),
				})
					.then((_data) => _data.json())
					.then((data) => {
						if (data.materie) {
							setVotiDocenti(data.materie);
						} else {
							setVotiDocenti({});
							Swal.fire({
								icon: "error",
								title: "Errore",
								text: data.messaggio,
								confirmButtonColor: "#008ed4"
							});
						}
					});
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "Docente non selezionato",
				text: "Per visualizzare la media delle valutazioni ottenute da un docente è prima necessario selezionarlo.",
				confirmButtonColor: "#008ed4"
			});
		}
	};

	const render_select_docenti = () => {
		if (
			dataDocenti !== undefined &&
			Object.keys(dataDocenti).length !== 0
		) {
			return dataDocenti.map((e, i) => {
				const valore = e.nome + " " + e.cognome;
				return (
					<option key={i} value={e.email}>
						{valore}
					</option>
				);
			});
		}
	};

	const handleVotiMateria = (voti) => {
		let obj = {
			labels: [],
			datasets: [
				{
					label: "Voti Materia",
					data: [],
					backgroundColor: "rgba(0,142,212,1)",
					hoverBackgroundColor: "rgba(0,182,252,0.5)",
				},
			],
		};
		voti.map((e, i) => {
			obj.labels.push(i + 1);
			obj.datasets[0].data = [...obj.datasets[0].data, e.voto];
		});

		return obj;
	};

	const handleTableVotiDocenti = () => {
		if (
			Object.keys(votiDocenti).length !== 0 &&
			votiDocenti !== undefined
		) {
			if (votiDocenti.messaggio) {
				Swal.fire({
					title: "Attenzione",
					text: votiDocenti.messaggio,
					icon: "warning",
                    confirmButtonColor: "#008ed4"
				});
			} else
				return votiDocenti.map((e, i) => {
					const data = handleVotiMateria(e.valutazioni);
					const options = {
						type: "bar",
						data: data,
						options: {
							scales: { y: { beginAtZero: false } },
						},
						responsive: true,
					};
					return (
						<span
							key={i}
							className="centerDiv d-flex flex-column w-100 my-3"
						>
							Materia: {e.nome} <br />
							<Bar options={options} data={data}></Bar>
						</span>
					);
				});
		}
	};

	const showDomande = () => {
		if (!votiDocenti.messaggio && Object.keys(votiDocenti).length !== 0) {
			const valutazioni = votiDocenti[0].valutazioni;
			return valutazioni.map((e, i) => {
				return (
					<div key={i} className="singleDomanda">
						N°{i + 1}: {e.domanda}
					</div>
				);
			});
		}
	};

	const buttonReset = () => {
		// ritorna il bottone di reset + la scritta "domande" in alto
		if (
			Object.keys(votiDocenti).length !== 0 &&
			votiDocenti !== undefined &&
			votiDocenti.messaggio === undefined
		)
			return (
				<>
					<button
						className="littleAdminButton m-2"
						onClick={() => setVotiDocenti({})}
					>
						Pulisci Schermo
					</button>
					<div className="fs-2">Domande:</div>
				</>
			);
	};

	document.title = "Gestione delle valutazioni - Valutazione Docenti";

	return (
		<div className="container-fluid">
			<Header />
			<div className="row justify-content-center align-items-center mt-3">
				<span className="col-12 d-flex align-items-center justify-content-center mt-3">
					Pannello di Amministrazione
				</span>
				<div className="d-flex justify-content-center align-items-center flex-column mt-5">
					<span className="infoMaterie">
						Nel menu a tendina sottostante è possibile selezionare il docente del quale si desiderano vedere le medie delle valutazioni ottenute.
					</span>
					<select
						ref={selectDocente}
						className="adminButton adminSelect m-2"
					>
						<option key={-1} value="">Selezionare un docente</option>
						{render_select_docenti()}
					</select>
					<button
						onClick={view_docente_function}
						className="adminButton m-2"
					>
						Visualizza Docente
					</button>
					<div className="d-flex justify-content-space-center align-items-center flex-column mt-5">
						{buttonReset()}
						<div>{showDomande()}</div>
						{handleTableVotiDocenti()}
					</div>
				</div>
				<div
					className="d-flex justify-content-center align-items-center flex-column mt-5"
					ref={divPulsante}
				></div>
			</div>
			<Footer />
			<Aiuto pagina={"Admin"}/>
			<BackToTopButton />
		</div>
	);
};

export default Admin;
