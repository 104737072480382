import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../css/Valutazione.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Voto from "../components/Voto";
import Swal from "sweetalert2";
import packageInfo from "../../config.json";
import BackToTopButton from "../components/BackToTopButton";
import useCommon from "../useCommon";
import BackButton from "../components/BackButton";
import useDynamicRefs from "use-dynamic-refs";
import Aiuto from "../components/Aiuto";

const Valutazione = () => {
	// Dichiarazione costanti dal json
	const SERVER = packageInfo.SERVER;
	const GET_DOMANDE = packageInfo.GET_DOMANDE;
	const VALUTA_DOCENTE = packageInfo.VALUTA_DOCENTE;
	// importo massimo e minimo voti
	const MIN = packageInfo.MIN;
	const MAX = packageInfo.MAX;

	const [domande, setDomande] = useState([]); // array globale con le domande ricavate dal JSON
	const { state } = useLocation();
	const [infos, setinfos] = useState({});
	const navigate = useNavigate();
	const [voti, setVoti] = useState({});
	const { notLogged } = useCommon();
	const contenitoreDomande = useRef();
	const selectMaterie = useRef();
	const [getRef, setRef] = useDynamicRefs();

	useEffect(() => {
		// ottengo le domande dal JSON
		const getDomande = () => {
			fetch(SERVER + GET_DOMANDE, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					token: JSON.parse(localStorage.getItem("vd-info")).token,
				}),
			})
				.then((dati) => dati.json())
				.then((elenco) => {
					// salvo il risultato in un array globale
					setDomande([...elenco]);
				});
		};

		// se non ci sono le infomazioni necessarie faccio rifare il login
		if (localStorage.getItem("vd-info") === null) {
			notLogged();
		} else if (Object.keys(JSON.parse(localStorage.getItem("vd-info"))).length === 0) {
			notLogged();
		} else if (state === null) {
			Swal.fire({
				icon: "warning",
				title: "Dati del professore non presenti",
				text: "Non vi sono i dati relativi al professore scelto, questo può capitare poiché si ha aperto questa pagina senza aver selezionato un professore dall'elenco di quelli valutabili.",
				confirmButtonText: "Torna all'elenco dei professori",
				confirmButtonColor: "#008ed4"
			}).then((result) => {
				if (result.isConfirmed) {
					navigate("/Elenco");
				}
			});
		} else {
			const { nome } = state; // ricavo nome
			const { cognome } = state; // ricavo cognome
			const { mail } = state;
			const { materia } = state; // ricavo materia
			// salvo le informazioni destrutturate nelle variabili
			infos["nome"] = nome;
			infos["cognome"] = cognome;
			infos["mail"] = mail;
			infos["materia"] = materia;
			setinfos({ ...infos });
			getDomande();
		}
	}, []);

	const handleInputChange = (childId, value) => {
		setVoti((prevVoti) => ({ ...prevVoti, [childId]: value }));
	};

	const convalidaVoto = async () => {
		let token = JSON.parse(localStorage.getItem("vd-info")).token;
		let mail_studente = JSON.parse(localStorage.getItem("vd-info")).email;
		let mail_docente = infos["mail"];
		let materie = selectMaterie.current.value;
		materie = materie.split(", ");
		let inviate = 0;
		let corrette = true;
		// recupero array di objects delle valutazioni
		let valutazioni = [];
		Object.keys(voti).forEach((e) => {
			valutazioni.push({
				idDomanda: e,
				voto: voti[e],
			});
		});

		for (let _materia of materie) {
			// ! rendo gli invii sincroni così che il server possa riceverli uno dopo l'altro e gestire correttamente i docenti ancora da valutare
			const response = await fetch(SERVER + VALUTA_DOCENTE, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					mailDocente: mail_docente,
					mailStudente: mail_studente,
					token: token,
					materia: {
						nomeMateria: _materia,
						valutazioni: valutazioni,
					},
				}),
			});
			const data = await response.json();
			inviate++; // incremento il contatore delle valutazioni inviate
			if (data.messaggio === "Valutazione inserita con successo") {
				if (corrette) console.log();
			} else {
				corrette = false;
			}
			// se le votazioni sono state tutte inviate
			if (inviate === materie.length) {
				// se sono arrivate tutte correttamente
				if (corrette) {
					Swal.fire({
						icon: "info",
						title: "Informazioni sull'invio al server",
						text: "Valutazione inserita con successo",
						confirmButtonText: "Torna all'elenco dei docenti",
						confirmButtonColor: "#008ed4"
					}).then((data) => {
						if (data.isConfirmed) {
							navigate("/Elenco");
						}
					});
				} else {
					Swal.fire({
						icon: "error",
						title: "Informazioni sull'invio al server",
						// text: data.messaggio,
						text: "Errore durante l'invio delle valutazioni, riprovare. In caso il problema persista provare a effettuare il logout, riprovare il login e inviare nuovamente la valutazione.",
						confirmButtonText: "Capito",
						confirmButtonColor: "#008ed4"
					});
				}
			}
		}
	};

	const controllo = () => {
		// controllo che siano tutte votate
		if (Object.keys(voti).length < domande.length) {
			// salvo i figli del contenitore in cui inserisco le domande --> sono i contenitori dei componenti voto
			let pargoli = contenitoreDomande.current.children;
			for (let figlio of pargoli) {
				// coloro di rosso tutti i testi delle domande
				figlio.children[0].style.color = "red";
				// cambio le proprietà del bordo
				// figlio.style.borderColor = "red";
				// figlio.style.borderWidth = "3px";
				// ripristino il colore delle domande valutate
				if (Object.keys(voti).includes(figlio.children[1].name)) {
					figlio.children[0].style.color = "black";
					// figlio.style.borderColor = "#D9D9D9";
					// figlio.style.borderWidth = "3px";
				}
			}
			setVoti(voti);
			// notifico l'utente
			Swal.fire({
				icon: "warning",
				title: "Numero di voti insufficiente",
				text: "Assicurarsi di aver votato tutte le domande. Le domande colorate di rosso sono quelle che ancora necessitano di ricevere una valutazione.",
				confirmButtonColor: "#008ed4"
			});
		} else {
			// converto da stringhe a interi e salvo in un array
			let val = Object.values(voti).map((v) => {
				return parseInt(v);
			});
			// controllo il range dei voti
			if (val.every(checkValore)) {
				convalidaVoto();
			} else {
				Swal.fire({
					icon: "error",
					title: "Voti non accettabili",
					text: `I voti inseriti non rispettano i requisiti richiesti. Il voto minimo ammesso è ${MIN} e il voto massimo ammesso è ${MAX}.`,
                    confirmButtonColor: "#008ed4"
				});
			}
		}
	};

	const checkValore = (voto) => {
		return voto >= MIN && voto <= MAX;
	};

	// mostra domande
	const mostraDomande = () => {
		return domande.map((e) => {
			let id = e.id;
			return (
				<Voto
					min={MIN}
					max={MAX}
					key={id}
					testoDomanda={e["domanda"]}
					id={id}
					valutazioni={voti}
					onInputChange={handleInputChange}
				/>
			);
		});
	};

	// chiedo conferma prima di cancellare
	const confermaCancella = () => {
		Swal.fire({
			title: "Sei sicuro di voler cancellare i tuoi voti?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#42C6FF",
			cancelButtonColor: "#D9D9D9",
			confirmButtonText: "Sì, voglio cancellare i miei voti",
			cancelButtonText: "Annulla",
		}).then((result) => {
			if (result.isConfirmed) {
				resetVoti();
			} else {
				// salvo i figli del contenitore in cui inserisco le domande --> sono i contenitori dei componenti voto
				let pargoli = contenitoreDomande.current.children;
				for (let figlio of pargoli) {
					// ottengo il valore dell'attributo name
					let name = figlio.children[1].getAttribute("name");
					try {
						// aggiorno il valore con il voto inserito se è presente nella lista
						figlio.children[1].setAttribute("value", voti[name]);
					} catch {}
				}
			}
		});
	};

	const resetVoti = () => {
		setVoti({});
		domande.forEach((d)=> {
			getRef(d.id).current.value = MIN;
		});
	}

	useEffect(() => {
		selectMaterie.current.innerHTML = "";
		// aggiungo un opzione tutte di default
		if (infos["materia"].length > 1) {
			let val = infos["materia"].join(", "); // come valore imposto tutte le materie del prof
			selectMaterie.current.innerHTML =
				"<option style='font-style: italic' value='" + val + "'>Tutte le materie</option>";
		}
		const inserisciMaterie = () => {
			return infos["materia"].map((e, i) => {
				return (
					"<option style='font-style: italic' value='" + e + "'>" + e[0] + e.slice(1).toLowerCase() + "</option>"
				);
			});
		};

		selectMaterie.current.innerHTML += inserisciMaterie();
	}, [infos]);

	// porto tutti gli slider al valore minimo
	useEffect(()=> {
		resetVoti();
	}, [contenitoreDomande, domande]);

	document.title = "Valutazione " + infos["nome"] + " " + infos["cognome"] + " - Valutazione Docenti";

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			return undefined
		}
	};

	return (
		<div className="container-fluid">
			<Header />
				{/* info del prof */}
				<div className="row justify-content-center align-items-center mt-5 w-75 m-auto pb-5">
					<div className="col-12">
						<BackButton msg="Torna all'elenco dei professori da valutare" />
					</div>
					<div className="col-12 text-center">
						<h1 className="infos" style={{ color: "black" }}>
							{infos["nome"] + " " + infos["cognome"]}
						</h1>
					</div>
					<div className="col-12 text-center">
						{/* inserisco la select per selezionare le materie da valutare */}
						<select
							name="selectMaterie"
							id="selectMaterie"
							ref={selectMaterie}
							style={{ fontSize: "25px", fontStyle: "italic" }}
						></select>
						<br />
						<span style={{ fontSize: "25px" }}>
							Nel menu a tendina precedente è possibile selezionare la
							materia (o le materie) in cui valutare{" "}
							{infos["nome"] + " " + infos["cognome"]}
						</span>
					</div>
				</div>
				{/* schermata valutazione */}
				<form onKeyDown={handleKeyDown}>
					<div
						className="row justify-content-center align-items-center mt-5 w-75 m-auto"
						ref={contenitoreDomande}
					>
							{mostraDomande()}
					</div>
				</form>
				{/* pulsanti */}
				<div className="row justify-content-center align-items-center mt-5 mb-5 w-75 m-auto">
					<div className="col-12 col-lg-6 text-center m-2">
						{/* ! quando cancella viene premuto pulisco lo stato --> posso controllare che prima di inviare si siano votate tutte le domande */}
						<button
							className="button"
							type="reset"
							// style={{"backgroundColor": "red", "borderColor": "red"}}
							onClick={confermaCancella}
						>
							<i className="bi bi-trash3"></i>&nbsp;&nbsp;CANCELLA
						</button>
					</div>
					<div className="col-12 col-lg-6 text-center m-2">
						<button
							className="button"
							type="button"
							// style={{"backgroundColor": "green", "borderColor": "green"}}
							onClick={controllo}
						>
							<i className="bi bi-send"></i>&nbsp;&nbsp;INVIA
						</button>
					</div>
				</div>
			<Footer />
			<Aiuto pagina={"Valutazione"}/>
			<BackToTopButton />
		</div>
	);
};

export default Valutazione;
