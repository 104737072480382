import React from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Backbutton.css";

const BackButton = (props) => {
	const navigate = useNavigate();
	const { msg } = props;
	return (
		<>
			<button
				// bottone desktop
				className="backbtn d-none d-md-block"
				onClick={() => navigate(-1)}
			>
				<i className="bi bi-arrow-left"></i>&nbsp;&nbsp;{msg}
			</button>
		</>
	);
};

export default BackButton;
